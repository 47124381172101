/*--------------------------------------------------------------
1.0 Custom Fonts
--------------------------------------------------------------*/
@import "fonts";


/*--------------------------------------------------------------
1.0 Fontawesome
--------------------------------------------------------------*/
@import "./font-awesome/scss/font-awesome.scss";


/*--------------------------------------------------------------
1.0 Bootstrap
--------------------------------------------------------------*/
@import "./bootstrap";


/*--------------------------------------------------------------
1.0 Simple CSS3 Animations
--------------------------------------------------------------*/
@import "animate/animate.scss";


/*--------------------------------------------------------------
1.0 staggering animation
--------------------------------------------------------------*/
@import "animate/stagger.scss";

/* ========= GENERAL STYLING=========== */

body {
    overflow-x: hidden;
    background-color: rgba(107, 87, 75, 0.1);
    font-family: raleway-medium;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    margin: 0;
    padding: 0;
}

body::-webkit-scrollbar {
    width: 12px;
    background-color: #cccccc;
}

body::-webkit-scrollbar-thumb {
    background-color: #0073a5;
}

body::-webkit-scrollbar-thumb:hover {
    background-color: #c49f47;
    border: 1px solid #333333;
}

body::-webkit-scrollbar-thumb:active {
    background-color: #c49f47;
    border: 1px solid #333333;
}

body::-webkit-scrollbar-track {
    border: 1px gray solid;
    box-shadow: 0 0 2px gray inset;
}


// Progress
.progress {
    margin-bottom: 20px;
    transition: all 0.2s ease;
    border-radius: 0;
}

.progress-step {
    display: block;
    font-size: 13px;
    color: #8c6403;
    font-weight: bold;
    margin-bottom: 18px;
    position: relative;
    text-align: left;
    font-family: gotham_light;
    text-transform: uppercase;
    width: 100%;
}

#registerCaptcha{
    margin-bottom: 3%;  
}


.form-group label.question-label {
    font-size: 0.9em;
    font-weight: 400;
    text-transform: uppercase;

    span.tiny {
        font-size: 0.8em;
        font-style: italic;
    }
    span.number {
        font-size: 0.9em;
        text-transform: none;
        margin-right: 5px;
        font-family: gotham_light;
        font-weight: 600;
        color:#0073a5;
    }
}

.form-group small.information {
    text-transform: lowercase;
    font-style: italic;
    color: #8c6403;
}

.trash {
    cursor: pointer;
}

.trash-big {
    cursor: pointer;
    font-size: 16px;
}

.tinyseparator {
    width: 20px;
    display: inline-block;
}

.centered-content {
    margin: 9% auto;
}

.uppercase {
    text-transform: uppercase;
}

.yesno {
    min-height: 100px;
}

input.form-control,
textarea.form-control,
select.form-control {
    border-radius: 0;
}

input[type="text"] {
    font-size: 1.2em;
}

textarea.form-control {
    width: 100%;
}

.radio,
.checkbox{
    margin-left: 0;
    margin-bottom: 10px;
    margin-top:0;
}
textarea.form-control {
    margin:0;
}

input[type="radio"],
input[type="checkbox"] {
    height: 18px;
    width: 18px;
    margin: 1px 0 0 0;
}

.radio label,
.checkbox label {
    margin-right: 2%;
    font-size: 1em;
}


.panel,
.btn {
    border-radius: 0;
}

.page-header {
    margin: 10px 0 20px !important;
}


.sub-heading {
    margin-bottom: 2%;
}

.centered-text {
    text-align: center;
    margin-top: 2em;
}

.centered {
    text-align: center;
    width: 100%;
    display: inline-block;
}

.zepre-primary-button-small {
    display: inline-block;
    margin-left: 3%;
    text-transform: uppercase;
    border: 1px solid #0073a5;
    border-radius: 3px;
    font-size: 10px;
    line-height: 10px;
    padding: 0 12px;
    background-color: #fff;
    color: #0073a5;
    height: 22px;
    opacity: 1;
}

.zepre-primary-button {
    display: inline-block;
    margin-left: 3%;
    text-transform: uppercase;
    border: 2px solid #0073a5;
    border-radius: 3px;
    font-size: 12px;
    line-height: 12px;
    padding: 0 15px;
    background-color: #fff;
    color: #0073a5;
    height: 30px;
    opacity: 1;
}

.zepre-primary-button:hover,
.zepre-primary-button-small:hover {
    background-color: #0073a5;
    color: #fff;
}

.zepre-brown-button {
    display: inline-block;
    margin-left: 3%;
    text-transform: uppercase;
    border: 2px solid #c49f47;
    border-radius: 3px;
    font-size: 12px;
    line-height: 12px;
    padding: 0 15px;
    background-color: #c49f47;
    height: 30px;
    opacity: 1;
    color: #fff;
}

.zepre-brown-button:hover {
    background-color: #fff;
    color: #c49f47;
}

.zepre-primary-button:disabled,
.zepre-brown-button:disabled {
    background-color: #eaeae9;
    opacity: 0.3;
    cursor: not-allowed;
}

.go-up {
    i {
        font-size: 2em;
        color: #0073a5;
    }
}


.form-group {
    margin-bottom: 30px;

    .error {
        color: red;
        padding: 1%;
        font-size: 12px;
        display: inline;
    }

    label {
        span.required {
            color: red;
            font-size: 1.5em;
        }
    }

    input[type="checkbox"] {
        height: 16px;
        width: 18px;
        margin: 1px 1px 1px 5px;
        display: inline-block;
    }

}


.panel-primary {
    border-color: #c49f47;
    margin-bottom: 10%;
    margin-top: 8%;
}

.panel-primary>.panel-heading {
    color: #fff;
    background-color: #c49f47;
    border-color: #c49f47;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}


::-webkit-input-placeholder {

    color: rgba(168, 169, 171, 0.7);
    font-size: 12px;

}

::-moz-placeholder {

    color: rgba(168, 169, 171, 0.7);
    font-size: 12px;

}

:-ms-input-placeholder {

    color: rgba(168, 169, 171, 0.7);
    font-size: 12px;

}

:-moz-placeholder {

    color: rgba(168, 169, 171, 0.7);
    font-size: 12px;

}


.hide {
    display: none !important;
}

.inline {
    display: inline-block !important;
}

.show {
    display: block !important;
}

.path {
    stroke-dasharray: 20;
    animation: dash 10s linear backwards infinite;
}

@keyframes dash {

    from {
        stroke-dashoffset: 1000;
    }

    to {
        stroke-dashoffset: 20;
    }
}

@-webkit-keyframes bounceDelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes bounceDelay {

    0%,
    80%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    40% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

.loading-message {
    display: inline-block;
    min-width: 125px;
    margin-left: -60px;
    padding: 10px;
    margin: 0 auto;
    color: #000 !important;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
}

.loading-message.loading-message-boxed {
    border: 1px solid #ddd;
    background-color: #eee;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.loading-message>span {
    line-height: 20px;
    vertical-align: middle;
}

.page-loading {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 125px;
    margin-left: -60px;
    margin-top: -30px;
    padding: 7px;
    text-align: center;
    color: #333;
    font-size: 13px;
    border: 1px solid #ddd;
    background-color: #eee;
    vertical-align: middle;
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.page-loading>span {
    line-height: 20px;
    vertical-align: middle;
}

.page-spinner-bar {
    flex: 1;
    margin: auto;
    z-index: 10051;

    span {
        color: #0073a5;
    }
}

.page-spinner-bar>div {
    margin: 0 5px;
    width: 18px;
    height: 18px;
    background: #0073a5;
    border-radius: 100% !important;
    display: inline-block;
    -webkit-animation: bounceDelay 1.4s infinite ease-in-out;
    animation: bounceDelay 1.4s infinite ease-in-out;
    /* Prevent first frame from flickering when animation starts */
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.page-spinner-bar .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.page-spinner-bar .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.block-spinner-bar {
    display: inline-block;
    width: 80px;
    text-align: center;
}

.block-spinner-bar>div {
    margin: 0 2px;
    width: 15px;
    height: 15px;
    background: #eee;
    border-radius: 100% !important;
    display: inline-block;
    -webkit-animation: bounceDelay 1.4s infinite ease-in-out;
    animation: bounceDelay 1.4s infinite ease-in-out;
    /* Prevent first frame from flickering when animation starts */
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.block-spinner-bar .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.block-spinner-bar .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

//End of page loading spinner

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 7;
    display: flex;
    text-align: center;
}

.small-overlay {
    position: relative;
    display: flex;
    background: transparent;
    z-index: 6;
    width: 100%;
    height: 200px;
    text-align: center;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 6;
    text-align: center;

    figure.closer {
        background: url(../img/closer.png) center center no-repeat;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 16%;
        right: 2%;
        background-size: 100%;
        margin: 5px;
        z-index: 7;
        cursor: pointer;
    }

    figure.small-closer {
        background: url(../img/closer.png) center center no-repeat;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 20%;
        right: 6%;
        background-size: 100%;
        margin: 5px;
        z-index: 7;
        cursor: pointer;
    }
}

/* ======== Scroll to Top ========= */

.scroll-top-wrapper {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    text-align: center;
    z-index: 99999999;
    background-color: #fff;
    color: #eeeeee;
    width: 50px;
    height: 48px;
    line-height: 48px;
    right: 30px;
    bottom: 10%;
    padding-top: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    border: 1px solid #0073a5;
}

.scroll-top-wrapper:hover {
    background-color: #fff;
}

.scroll-top-wrapper.show {
    visibility: visible;
    cursor: pointer;
    opacity: 1.0;
}

.scroll-top-wrapper i.fa {
    line-height: inherit;
    color: #0073a5;
}

/* ======== End of Scroll to Top ========= */


.notifications,
.confirm {

    display: none;
    position: fixed;
    padding-bottom: 15px;
    z-index: 300;
    width: 50%;
    background: #fff;
    left: 23%;
    top: 40%;

    figure.closer {
        background: url(../img/closer.png) center center no-repeat;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 0;
        background-size: 100%;
        margin: 5px;
    }

    .notice_board,
    .confirm_board {
        padding: 15px;

        .notice_message,
        .confirm_message {
            font-size: 20px;
            text-align: center;
            font-family: HelveticaNeue-LightCond;
        }

    }

    .title {
        width: 100%;
        font-size: 23px;
        height: 40px;
        background-color: #0073a5;
        font-family: gotham_xlight;
        color: #fff;

        span {
            text-align: center;
            font-weight: 600;
            display: block;
            font-family: gotham_xlight;
            padding-top: 0.5%;
        }
    }



    p {
        font-family: gotham_xlight;
        color: #000;
        font-size: 18px;
        font-weight: 400;
    }

    label {
        font-size: 14px;
        color: #000;
        display: block;
        font-family: gotham_xlight;
        margin-top: 15px;
    }


    hr {
        border-top: 1px dashed #0073a5;
    }

    button {
        background: #0073a5;
        font-size: 16px;
        height: 37px;
        width: 46%;
        border-radius: 2px;
        border: none;
        outline: none;
        margin-top: 10px;
        font-family: HelveticaNeue-LightCond;
        text-align: left;
        text-indent: 20px;
        color: #fff;
        text-transform: uppercase;
        cursor: pointer;
    }

    button:hover {
        background: #fff;
        color: #0073a5;
        border: 1px solid #0073a5;
    }

    button.cancel {
        float: left;
    }

    button.ok {
        float: right;
    }

}

//End of .notifications, .confirm

.main-error {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    height: 100px;
    border-bottom: 2px solid #0073a5;
    margin-top: 105px;
    padding: 2%;
    color: red;
}

header {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    height: 100px;
    border-bottom: 2px solid #0073a5;

    .logo {
        width: 90px;
        height: 90px;
        background-image: url(../img/logo.png);
        background-repeat: no-repeat;
        background-size: 100%;
        float: left;
        margin-left: 5%;
    }

    .sub {
        float: left;
        width: 235px;
        height: 100px;
        text-align: left;
        color: #0073a5;

        .sub-top {
            text-transform: uppercase;
            font-size: 2em;
        }

        .sub-middle {
            font-size: 1.2em;
        }

        .sub-bottom {
            font-size: 1em;
        }

    }

    ul.main {
        width: 40%;
        color: #ffffff;
        list-style-type: none;
        float: right;
        margin-top: 2.5%;
        padding: 0;

        li {
            display: inline-block;
            margin-left: 3%;
            text-transform: uppercase;
            border: 2px solid #0073a5;
            border-radius: 3px;
            font-size: 12px;
            width: 90px;

            a {
                color: #0073a5;
                width: 100%;
                line-height: 2.4;
                padding: 1.5%;
                display: block;
                height: 30px;
            }

            a:hover {
                text-decoration: none;
                color: #ffffff;
            }

            a:active,
            .active {
                text-decoration: none;
                color: #ffffff;
                background-color: #0073a5;
            }


            .header-button {
                text-transform: uppercase;
                border: none;
                border-radius: 3px;
                background-color: #ffffff;
                color: #0073a5;
                font-size: 12px;
                width: 86px;
                line-height: 2.4;
                padding: 1.5%;
                display: inline-block;
                height: 30px;
            }

            .header-button:hover {
                background-color: #0073a5;
                color: #ffffff;
            }

            .header-button:disabled {
                background-color: #ffffff;
                color: #0073a5;
                opacity: 0.3;
            }

        }

        li:hover {
            background-color: #0073a5;
            color: #ffffff;
        }
    }

    ul.mobile {
        width: 25%;
        color: #ffffff;
        list-style-type: none;
        float: right;
        margin-top: 2.5%;
        margin-right: 5%;
        padding: 0;
        display: none;

        li {
            display: inline-block;
            margin-left: 3%;
            text-transform: uppercase;
            border: 2px solid #0073a5;
            font-size: 12px;
            width: 100%;
            border-bottom: none;
            background-color: #fff;

            a {
                color: #0073a5;
                width: 100%;
                line-height: 2.4;
                padding: 1.5%;
                display: block;
                height: 30px;
            }

            a:hover {
                text-decoration: none;
                color: #ffffff;
            }

            a:active,
            .active {
                text-decoration: none;
                color: #ffffff;
                background-color: #0073a5;
            }


            .header-button {
                text-transform: uppercase;
                border: none;
                border-radius: 3px;
                background-color: #ffffff;
                color: #0073a5;
                font-size: 12px;
                width: 86px;
                line-height: 2.4;
                padding: 1.5%;
                display: inline-block;
                height: 30px;
            }

            .header-button:hover {
                background-color: #0073a5;
                color: #ffffff;
            }

            .header-button:disabled {
                background-color: #ffffff;
                color: #0073a5;
                opacity: 0.3;
            }

        }

        li:last-child {
            border-bottom: 2px solid #0073a5;
        }

        li:hover {
            background-color: #0073a5;
            color: #ffffff;
        }
    }

    .mobile-button {
        width: 50px;
        height: 50px;
        display: none;
        float: right;
        margin-top: 4%;
        padding: 0;
        cursor: pointer;

        i {
            font-size: 25px;
        }

    }



}

//End of header


footer {
    width: 100%;
    background-color: #0073a5;
    background-size: 100%;
    height: 80px;
    z-index: 2;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding-top: 10px;
    border-top: 1px dashed #fff;

    ul {
        width: 100%;
        color: #ffffff;
        list-style-type: none;

        li {
            display: inline-block;
            margin-left: 2%;
            margin-top: 1%;
            text-transform: uppercase;

            a {
                color: #cbcbcb;
            }

            a:hover {
                text-decoration: underline;
                color: #cbcbcb;
            }

            a:active,
            a.active {
                text-decoration: underline;
                color: #ffffff;
            }

            .footer-button {
                text-transform: uppercase;
                border: none;
                border-radius: 3px;
                background-color: #ffffff;
                color: #0073a5;
                font-size: 12px;
                width: 86px;
                line-height: 2.4;
                padding: 1.5%;
                display: inline-block;
                height: 30px;
            }

            .footer-button:hover {
                background-color: #0073a5;
                border: 1px solid #ffffff;
                color: #ffffff;
            }

            .footer-button:disabled {
                background-color: #ffffff;
                color: #0073a5;
                opacity: 0.3;
            }


        }
        li.version{
            float: right;
            margin-right: 10px;
            text-transform: lowercase;
            font-size: 1rem;
        }
    }

}

//End of header

.feedback-page{
    margin-top: 10px;
    h5{
        text-transform: uppercase;
    }
    h4, h5, p{
        text-align: center;
    }
    textarea{
        margin-left: 0;
        width: 100%;
    }
}


.landing-page,
.contact-page,
.login-page,
.register-page,
.catalog-page,
.error-page,
.quotes-page,
.profile-page {
    width: 100%;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    span.error {
        clear: both;
        color: red;
        margin: 1% auto;
        display: block;
    }

    span.success {
        clear: both;
        color: green;
        margin: 1% auto;
        display: block;
    }
}

.quotes-page {
    .quotes-list, .quotes-detail{
        margin-top: 15px;
    }

    .quotes-list{
        padding-left: 15px;
        max-height: 1000px;
        overflow-y: auto;
        padding-top: 15px;
        padding-bottom: 15px;
        .list-group{
            padding-right: 0;
        }
        .list-group-item.complete{
            text-align: left;
            cursor: pointer;
            font-size: 12px;
        }

        .list-group-item.draft{
            text-align: left;
            font-size: 12px;
        }

        .list-group-item button{
            color: #ffffff;
            background-color: #c49f47;
            width: 80px;
            font-size: 10px;
            border-radius: 3px;
        }

        .list-group-item:last-child {
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
        }
        .list-group-item:first-child {
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
        }
    }

    .quotes-detail{
        padding-right: 15px;
    }
    
}

.landing-page {
    .slider-loader {
        position: relative;
        display: flex;
        background: transparent;
        width: 100%;
        height: 600px;
        text-align: center;
        margin-top: 100px;
    }
}

.contact-page,
.login-page,
.register-page,
.profile-page,
.catalog-page,
.details-page,
.quotes-page,
.quotation-page {
    margin-top: 100px;
    font-family: raleway-medium;
    font-size: 14px;

    .custom-button {
        width: 60%;
        text-transform: uppercase;
        float: left;
        border: 3px solid #0073a5;
        border-radius: 3px;
        background-color: #ffffff;
        color: #0073a5;
        font-size: 12px;
        display: block;
    }

    .custom-button:hover {
        background-color: #0073a5;
        color: #ffffff;
    }

    .custom-button:disabled {
        background-color: #ffffff;
        color: #0073a5;
        opacity: 0.3;
    }
}


.login-page,
.register-page,
.quotes-page,
.profile-page {
    padding: 3% 0;
    min-height: 540px;

    .title {
        text-transform: uppercase;
        color: #0073a5;
        border-bottom: 1px solid #0073a5;
        margin: 0 auto;
        width: 55%;
        padding-bottom: 10px;
    }

    .login-form,
    .register-form,
    .feedback-form,
    .profile-form {
        width: 90%;
        margin: 2% auto;
        text-align: left;

        .register_link {
            text-align: right;
            font-size: 1em;
            float: right;
            display: inline-block;
            cursor: pointer;
        }

        .register_span {
            text-align: right;
            font-size: 1em;
            float: right;
            display: inline-block;
            cursor: pointer;
            color: #0073a5;
            text-decoration: underline;
        }

    }

    .list-group-item {
        .profile-label {
            color: #000;
            font-weight: normal;
        }

        .profile-value {
            color: #0073a5;
            font-weight: normal;
        }
    }

}


.register-partner-page {
    padding: 3% 0;
    min-height: 360px;

    .heading {
        text-transform: uppercase;
        color: #0073a5;
        border-bottom: 1px solid #0073a5;
        margin: 0 auto;
        width: 55%;
        padding-bottom: 10px;
        margin-bottom: 0;
        text-align: center;
    }

    .register-partner-form {
        width: 100%;
        margin: 2% auto;
        text-align: left;

        .custom-button {
            float: none !important;
            margin: 1% auto;
            display: block;
        }
    }

}

.error-page {

    color: red;

    .title {
        text-transform: uppercase;
        border-bottom: 1px solid red;
        margin: 0 auto;
        width: 55%;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }

    padding: 10%;

}

.catalog-page,
.details-page,
.quotation-page {

    margin-bottom: 80px;

    .title {
        text-transform: uppercase;
        color: #0073a5;
    }

    .filter-section {
        position: fixed;
        top: 30px;
        z-index: 2;
        width: 100%;

        input.search-box {
            width: 80%;
            margin: 0 auto;
            -webkit-transition: width 0.4s ease-in-out;
            transition: width 0.4s ease-in-out;
        }

        input.search-box:focus {
            width: 90%;
        }

        button.new-idea-button {
            width: 50%;
            text-align: center;
            margin: 0 auto;
        }

        hr {
            border-top: 1px dashed #0073a5;
            clear: both;
            margin: 4% 0;
        }

    }

    /*End of .filter-section*/


    .classes-container {
        margin-top: 0;
    }

    .class-container {
        margin-top: 10%;
    }

    .classes-list,
    .class-detail {

        padding: 0 3%;

        .panel {
            text-align: left;
        }

        .class-heading {
            text-transform: uppercase;
        }

        .class-image {
            -webkit-box-shadow: 2px 2px 5px 0px rgba(61, 104, 117, 0.5);
            -moz-box-shadow: 2px 2px 5px 0px rgba(61, 104, 117, 0.5);
            box-shadow: 2px 2px 5px 0px rgba(61, 104, 117, 0.5);
            transition: opacity 0.3s;
            transition: width 0.3s;
        }

        .main-class-item {
            min-height: 460px;
        }

        .main-class-content {
            min-height: 350px;
            max-height: 350px;
        }

        .readmore {
            display: inline-block;
            text-transform: uppercase;
            border: 2px solid #0073a5;
            border-radius: 3px;
            font-size: 11px;
            width: 100px;
            height: 28px;
            line-height: 2.4;
            text-align: center;
        }

        .readmore:hover {
            color: #ffffff;
            background-color: #0073a5;
            text-decoration: none;
        }

        .getquote {
            display: inline-block;
            text-transform: uppercase;
            border: 2px solid #c49f47;
            border-radius: 3px;
            font-size: 11px;
            width: 100px;
            height: 28px;
            line-height: 2.4;
            text-align: center;
            float: right;
            color: #c49f47;
        }

        .getquote:hover {
            color: #ffffff;
            background-color: #c49f47;
            text-decoration: none;
        }

        hr {
            clear: both;
            margin: 3% 0 1.5% 0;
            border-top: 1px dashed #0073a5;
        }

        .business-classes {

            width: 100%;

            .filter {
                width: 100%;
                display: flex;
                height: 90px;
                text-align: center;

                .filter-buttons {
                    margin-bottom: 1%;
                    text-align: left;
                    width: 100%;

                    h4 {
                        font-size: 12px;
                        text-transform: uppercase;
                    }

                    ul.filter-list {
                        width: 100%;
                        display: inline-block;
                        color: #ffffff;
                        list-style-type: none;
                        float: left;
                        padding: 0;
                        margin: 0;

                        li {
                            display: inline-block;
                            margin-left: 1%;
                            margin-bottom: 1%;
                            text-transform: uppercase;
                            border: 2px solid #0073a5;
                            border-radius: 3px;
                            font-size: 10px;
                            width: 155px;
                            color: #0073a5;
                            text-align: center;
                            padding: 5px;
                            cursor: pointer;
                        }

                        li.active {
                            background-color: #0073a5;
                            color: #ffffff;
                        }

                        li:hover {
                            background-color: #0073a5;
                            color: #ffffff;
                        }
                    }

                    //End of the ul.filter-list



                }

            }

            .sub-classes {
                width: 100%;
                display: flex;
                min-height: 500px;
                flex-wrap: wrap;

                .sub-class-heading {
                    text-transform: uppercase;
                    font-size: 0.9em;
                }

                .panel {
                    margin-bottom: 10%;
                }
            }

            .sub-class-item {
                min-height: 460px;
            }

            .sub-class-content {
                min-height: 350px;
                max-height: 350px;
            }

        }



    }

    .class-detail {
        .panel {
            margin: 0;
        }
    }

}

/*End of catalog-page*/


/*new partner modal*/

.new-partner-modal {
    background-color: #fff;
    min-height: 400px;
    width: 90%;
    position: fixed;
    top: 18%;
    left: 0;
    right: 0;
    padding: 3%;
    margin: 0 auto;
}

/*end of partner modal*/


.quotation-page {

    .quotation-container {
        padding: 2%;
        min-height: 450px;
        margin-bottom: 2%;
    }

    .title {
        text-transform: uppercase;
        color: #0073a5;
        border-bottom: 1px solid #0073a5;
        margin-bottom: 2%;
        font-size: 16px;
    }

    .sub-title {
        text-transform: uppercase;
        color: #0073a5;
        margin-bottom: 2%;
        font-size: 13px;
    }

    .quotation-form {
        margin: 0 auto;

        hr.separator {
            clear: both;
            margin: 2% 0 2% 0;
            border-top: 1px dashed #0073a5;
        }

        .form-group {
            margin-bottom: 10px;
        }

        .buttons {
            margin-top: 3%;
        }

        .discounts, .deductibles {
            margin-top: 3%;
            padding-left: 15px;
            padding-right: 15px;
            h6{
                color: #0073a5;
                font-size: 14px;
                text-transform: uppercase;
            }

            .discount-form, .deductibles-form{
                background-color: #eee;
                border-radius: 4px;
                padding: 20px;
                .rates{
                    font-size: 11px;
                    color: #0073a5;
                }
                .discount{
                    font-size: 14px;
                    color: #0073a5;
                    margin-bottom: 3%;
                }
            }
        }

        .sub-title {
            color: #0073a5;
            text-align: center;
        }

        .sub-title-confirm {
            color: #0073a5;
            text-align: left;
            text-transform: uppercase;
        }

        .successful {
            margin: 0 auto;
            text-align: center;
            display: block;
            color: #0073a5;

            i {
                font-size: 6em;
                color: #0073a5;
            }

        }

        .panel {
            margin: 3% 0;
        }

        .list-group-item {
            .confirm-label {
                color: #000;
                font-weight: normal;
            }

            .confirm-value {
                color: #0073a5;
                font-weight: normal;
            }
        }

        table.partners-table {

            width: 100%;
            margin: 2% auto;

            tr {
                padding: 1%;

                th {
                    background-color: rgba(204, 204, 204, 0.5);
                    padding: 0.4em;
                    border-top: 1px solid #868686;
                    border-bottom: 1px solid #868686;
                }

                td {
                    border-bottom: 1px solid #c49f47;
                    padding: 0.4em;

                    i {
                        font-size: 1.4em;
                    }
                }
            }

            tr:nth-child(even) {
                background-color: rgba(238, 238, 238, 0.3);
            }

        }

    }

}


/*Quick quotation page*/
.quick-page {

    margin-bottom: 80px;
    margin-top: 100px;
    font-family: gotham_light;
    font-size: 14px;

    .error {
        color: red;
        font-size: 12px;
        text-transform: lowercase;
    }

    .quick-container {
        padding: 2%;
        min-height: 450px;
        margin-bottom: 2%;
    }

    table td.value {
        font-weight: 600;
        span{
            font-weight: normal;
            font-family: gotham_light;
            font-size: 14px;
            display: block;
        }
    }

}

.confirmation-quick-page {
    margin: 0;
    font-family: gotham_light;
    font-size: 13px;

    .error {
        color: red;
        font-size: 12px;
        text-transform: lowercase;
    }

    .quick-container {
        padding: 2%;
        min-height: 450px;
        margin-bottom: 2%;

        .invoice-content {
            padding: 40px 30px;
        }
    }

    table td.value {
        font-weight: 600;
        span{
            font-weight: normal;
            font-family: gotham_light;
            font-size: 14px;
            display: block;
        }
    }
}


/*** Invoice page ***/
.invoice-content {
    background-color: #fff;
    padding: 80px 70px;
}

.invoice-content.bordered {
    border: 1px solid;
    border-color: #e7ecf1;
    background-color: rgba(231, 236, 241,0.3);
}

.invoice-content .invoice-head {
    margin-bottom: 50px;
}

.invoice-content .invoice-head .invoice-logo {
    text-align: center;
}

.invoice-content .invoice-head .invoice-logo>img {
    float: left;
    margin-right: 45px;
    width: 15%;
}

.invoice-content .invoice-head .invoice-logo>h1 {
    float: left;
    font-size: 17px;
    font-weight: 700;
    color: #39424a;
}

.invoice-content .invoice-head .company-address {
    text-align: right;
    font-size: 14px;
    color: #7e8691;
}

.invoice-content .invoice-title {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #9ea8b7;
    text-align: left;
}

.invoice-content .invoice-desc {
    font-size: 14px;
    color: #4e5a64;
    font-weight: 600;
    text-align: left;
}

.invoice-content .invoice-desc.inv-address {
    font-size: 13px;
    color: #7e8691;
    font-weight: 400;
}

.invoice-content .invoice-desc.grand-total {
    font-size: 16px;
    font-weight: 700;
}

.invoice-content .invoice-cust-add {
    margin-bottom: 50px;
}

.invoice-content .invoice-error {
    color: red;
}

.invoice-content .invoice-body .text-center {
    text-align: center;
}

.invoice-content .invoice-body tr:last-child {
    border-bottom: 1px solid #e7ecf1;
}

.invoice-content .invoice-body th,
.invoice-content .invoice-body td {
    vertical-align: middle;
    padding-left: 40px;
    text-align: left;
}

.invoice-subtotal{
    text-align: left;
}

.invoice-content .invoice-body th:first-child,
.invoice-content .invoice-body td:first-child {
    padding-left: 0;
}

.invoice-content .invoice-body th:last-child,
.invoice-content .invoice-body td:last-child {
    padding-right: 0;
}

.invoice-content .invoice-body td:first-child {
    min-width: 260px;
}

.invoice-content .invoice-body{
    table{
        tbody{
            tr td.value {
                font-weight: 600;
                span{
                    font-weight: normal;
                    font-family: gotham_light;
                    font-size: 14px;
                    display: block;
                }
            }
        }
    }
}

.invoice-content .invoice-body h3 {
    font-size: 14px;
    font-weight: 600;
    color: #4e5a64;
    margin-bottom: 0;
}

.invoice-content .invoice-body p {
    font-size: 13px;
    color: #7e8691;
}

.invoice-content .print-btn {
    float: right;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
}

@media (max-width: 1024px) {
    .invoice-content .invoice-head .invoice-logo>img {
        width: 130px;
        margin-top: 26px;
    }
}

@media (max-width: 991px) {
    .invoice-content {
        padding: 40px 30px;
    }
}

@media (max-width: 480px) {
    .invoice-content .invoice-head .invoice-logo>h1 {
        margin-top: 10px;
    }

    .invoice-content .invoice-head .company-address {
        font-size: 12px;
    }
}

/*End of invoice*/


/*Contact page styling*/

.contact-page {

    .flags-list {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            display: inline-block;
            width: 52px;
            height: 31px;
            margin-left: 2%;
            opacity: 0.4;

            figure {
                margin: 0;
                padding: 0;
                cursor: pointer;
                width: 52px;
                height: 31px;
            }

            .kenya {
                background: url(../img/kenya.png) center center no-repeat;
            }

            .zambia {
                background: url(../img/zambia.png) center center no-repeat;
            }

            .cameroon {
                background: url(../img/cameroon.png) center center no-repeat;
            }

            .sudan {
                background: url(../img/sudan.png) center center no-repeat;
            }

            .zimbambwe {
                background: url(../img/zimbambwe.png) center center no-repeat;
            }

            .ivory {
                background: url(../img/ivory.png) center center no-repeat;
            }



        }

        li.active {
            opacity: 1;
        }

        li.active::after {
            content: "";
            position: absolute;
            display: block;
            border-bottom: 2px solid green;
            width: 52px;
            height: 2px;
            margin-top: 5px;
        }
    }

    .contact-section {
        margin: 1% 0;

        h4 {
            margin: 1% 0;
        }

        figure {
            margin: 0;
            padding: 0;
            width: 114px;
            height: 67px;
            display: inline-block;
        }

        .kenya-big {
            background: url(../img/kenya-big.png) center center no-repeat;
        }

        .zambia-big {
            background: url(../img/zambia-big.png) center center no-repeat;
        }

        .cameroon-big {
            background: url(../img/cameroon-big.png) center center no-repeat;
        }

        .sudan-big {
            background: url(../img/sudan-big.png) center center no-repeat;
        }

        .zimbambwe-big {
            background: url(../img/zimbambwe-big.png) center center no-repeat;
        }

        .ivory-big {
            background: url(../img/ivory-big.png) center center no-repeat;
        }

    }

    .partners-divider {
        margin: 0;
    }

    .partners-list {

        padding: 0;
        margin: 0;
        list-style: none;

        li {
            display: inline-block;
            width: 365px;
            height: 110px;
            margin-left: 1%;



            figure {
                margin: 0;
                padding: 0;
                cursor: pointer;
                width: 365px;
                height: 110px;
                opacity: 0.5;
            }

            figure:hover {
                opacity: 1;
            }

            .pta {
                background: url(../img/pta.png) center center no-repeat;
                background-size: 100%;
            }

            .ati {
                background: url(../img/ati.png) center center no-repeat;
                background-size: 85%;
            }

            .com {
                background: url(../img/comesa.png) center center no-repeat;
                background-size: 100%;
            }

        }

    }

}

/*End of contact page styling*/

/*Card styling*/

.wrapper {
    max-width: 800px;
    margin: 0 auto;
    padding-left: 1em;
    padding-right: 1em;
}

/**
 * Card Helpers
 */
.border-tlr-radius {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.text-center {
    text-align: center;
}

.radius {
    border-radius: 2px;
}

.padding-tb {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
}

.shadowDepth0 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.shadowDepth1 {
    box-shadow:
        0 1px 3px rgba(0, 0, 0, 0.12),
        0 1px 2px rgba(0, 0, 0, 0.24);
}


.card {
    background-color: #fff;
    margin-bottom: 1.6rem;
}

.card__padding {
    padding: 1.6rem;
}

.card__image {
    min-height: 100px;
    background-color: #eee;
}

.card__image img {
    width: 100%;
    max-width: 100%;
    display: block;
}

.card__content {
    position: relative;
}

/* card meta */
.card__meta {
    margin-bottom: 2%;
}

.card__meta time {
    font-size: 1.5rem;
    color: #bbb;
    margin-left: 0.8rem;
}

/* card article */
.card__article a {
    text-decoration: none;
    color: #444;
    transition: all 0.5s ease;
}

.card__article a:hover {
    color: #2980b9;
}

/* card action */
.card__action {
    overflow: hidden;
    padding-right: 1.6rem;
    padding-left: 1.6rem;
    padding-bottom: 1.6rem;
    clear: both;
}

.card__author {
    font-size: 10px;
}

.card__author img,
.card__author-content {
    display: inline-block;
    vertical-align: middle;
}

.card__author img {
    border-radius: 50%;
    margin-right: 0.6em;
}

.card__share {
    float: right;
    position: relative;
    margin-top: -42px;
}

.card__social {
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
    width: 160px;
    transform: translateZ(0);
    transform: translateX(0px);
    transition: transform 0.35s ease;
}

.card__social--active {
    visibility: visible;
    /*z-index: 3;*/
    transform: translateZ(0);
    transform: translateX(-48px);
    transition: transform 0.35s ease;
}

.share-toggle {
    z-index: 2;
}

.share-toggle:before {
    content: "\f1e0";
    font-family: 'FontAwesome';
    color: #3498db;
}

.share-toggle.share-expanded:before {
    content: "\f00d";
}

.share-icon {
    display: inline-block;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border-radius: 50%;
    background-color: #fff;
    transition: all 0.3s ease;
    outline: 0;

    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.12),
        0 2px 4px rgba(0, 0, 0, 0.24);
}

.share-icon:hover,
.share-icon:focus {
    box-shadow:
        0 3px 6px rgba(0, 0, 0, 0.12),
        0 3px 6px rgba(0, 0, 0, 0.24);

    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}

.facebook {
    background-color: #3b5998;
}

.twitter {
    background-color: #00abe3;
}

.googleplus {
    background-color: #d3492c;
}

.facebook,
.twitter,
.googleplus {
    color: #fff;
}

.facebook:hover,
.twitter:hover,
.googleplus:hover {
    color: #eee;
}

/*End of card styling*/


/* ========= Tablets Portrait Custom Syling =========== */
@media screen and (max-width:754px) and (orientation: portrait) {

    .login-page,
    .register-page,
    .profile-page {

        .login-form,
        .feedback-form,
        .register-form,
        .profile-form {
            width: 85%;
            margin-bottom: 40%;
        }

    }

    header {

        ul.main {
            display: none;
        }

        ul.mobile {
            width: 25%;
            position: absolute;
            right: 0;
        }

        .mobile-button {
            margin-top: 6% !important;
            display: inline-block;
            margin-right: 3%;
        }

    }

}

/* ========= Phones Portrait Custom Syling =========== */
@media screen and (max-width:720px) and (orientation: portrait) {

    .login-page,
    .register-page,
    .profile-page {

        .login-form,
        .feedback-form,
        .register-form,
        .profile-form {
            width: 85%;
            margin-bottom: 40%;
        }

    }

    header {

        ul.main {
            display: none;
        }

        ul.mobile {
            width: 25%;
            position: absolute;
            right: 0;
        }

        .mobile-button {
            margin-top: 6% !important;
            display: inline-block;
            margin-right: 3%;
        }

    }

}

.read-more {
    color: #2185D0;
    font-size: 12px;
    text-transform: lowercase;
}

label {
    font-weight: normal;
}


label.section-total {
    color: #8c6403;
    font-weight: 600;
    margin-bottom: 2%;
}

td.empty {
    height: 36px;
}

td.subtitle {
    font-weight: 600;
}

td.draft {
    font-weight: 600;
    color: red;
}

.search-select {
    height: 34px;
}

.search-select>div {
    border-radius: 0;
}

.search-select {

    &__menu {
        border-radius: 0 !important;
    }

	&__menu-list{
        font-size: 1.05em;
        font-family: raleway-medium;
    }

    &__single-value{
        font-size: 1.05em;
        font-family: raleway-medium;
        color: #555555 !important;
    }

    &__placeholder{
        color: rgb(191, 191, 191) !important;
        font-size: 12px;
    }

    &__option--is-selected{
        background-color: #179ad6 !important;
    }

    &__control--is-focused{
        border-color: #179ad6 !important;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
}

.edit-button {
    width: 60px;
    padding: 3px;
    margin-left: 5%;
    cursor: pointer;
}

.edit-list {
    background-color: #f8f8f8;
}


.rc-tabs{
    border: none !important;
}

.rc-tabs-tab{
    font-size: 16px !important;
    margin: 0 10px !important;
}

.rc-tabs-nav{
    border-bottom: 1px dotted grey !important;
    margin-bottom: 10px !important;
}

.rc-tabs-nav-list{
     margin-bottom: 10px !important;
}

.rc-tabs-tab-active{
    color: #c49f47 !important
}

.rc-tabs-tab-btn:focus{
    outline: none !important;
}

.rc-tabs-tabpane-active:focus{
    outline: none !important;
}

.rc-tabs-ink-bar{
    background-color: #21BA45 !important;
}

.form-sub-title{
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #0073a5;
    font-weight: 600;
}

.error {
        color: red;
        font-size: 11px;
        display: inline;
    }