/* ========= Tablets Portrait Custom Syling =========== */
@media screen and (max-width:754px) and (orientation: portrait){

	header{		
		ul{
			width: 50%;
			padding:0;
		}
	}

	.landing-page, .contact-page, .login-page, .register-page, .catalog-page{
		margin-top: 100px;
	}

}


/* ========= END Tablets Portrait Custom Syling =========== */

