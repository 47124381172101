/* ========= Tablets Landscape Custom Syling =========== */
@media screen and (max-width:1006px) and (orientation: landscape){

	header{		
		ul{
			width: 45%;
			padding:0;
		}
	}

	.landing-page, .contact-page, .login-page, .register-page, .catalog-page{
		margin-top: 100px;
	}
}


/* ========= END Tablets Landscape Custom Syling =========== */

