/* ========= Phones Portrait Custom Syling =========== */
@media screen and (max-width:720px) and (orientation: portrait){

	header{		
		ul{
			width: 40%;
			padding:0;
		}
	}

	.landing-page, .contact-page, .login-page, .register-page, .catalog-page{
		margin-top: 100px;
	}

}


/* ========= END Phones Portrait Custom Syling =========== */

